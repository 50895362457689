import React, { useState } from "react";
import { isValidMobile } from "../../utils/validations";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../common/BaseUrl";

function AddUserModal({ isOpen, onClose, setUsersArray }) {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Call the handleSubmit function passed as prop
    try {
      if (!isValidMobile(userDetails.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }
      // Optionally, you can reset the form fields or close the modal here
      setUserDetails({
        name: userDetails.name,
        email: userDetails.email,
        mobile: userDetails.mobile,
        password: userDetails.password,
      });
      const response = await axios.post(
        `${BaseURL}/registerUser`,
        userDetails
      );
      if (
        response.status === 200 &&
        response.data.message === "Name must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "Please enter a valid email address"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "Password must be at least 6 characters long"
      ) {
        alert(response.data.message);
      }

      if (response.status === 201 && response.data.message=="User registered successfully") {
        alert("User registered successfully!");
        setUserDetails({
          name: "",
          email: "",
          mobile: "",
          password: "",
        });
        onClose(); // Close the modal
        navigate("/users"); // Redirect to home page after successful registration
      }
    } catch (error) {}
  };

  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add User</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#000",
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  value={userDetails.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={userDetails.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="mobile"
                  className="form-control"
                  placeholder="Mobile"
                  value={userDetails.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  value={userDetails.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button type="submit" className="btn btn-primary btn-block">
                  Add User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddUserModal;
