import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import Userstable from "./Userstable";
import EditUserModal from "./EditUserModal";
import DeleteModal from "./DeleteModal";
import AddUserModal from "./AddUserModal";
import { BaseURL } from "../common/BaseUrl";

export default function Users() {
  const [usersArray, setUsersArray] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage or session storage

      try {
        const response = await axios.get(`${BaseURL}/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token to Authorization header
          },
        });

        if (
          response.status === 201 &&
          response.data.message === "All User Details Collected"
        ) {
          setUsersArray(response.data.users);
        } else if (
          response.status === 200 &&
          response.data.message === "No users found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 500 &&
          error.response.data.message === "Internal server error"
        ) {
          alert(error.response.data.message);
        } else {
          console.error("Error fetching users:", error);
        }
      }
    };

    fetchData();
  }, [usersArray]); // Empty dependency array to run only on mount

  const handleEdit = (userId) => {
    const selected = usersArray.find((user) => user.id === userId);
    setSelectedUser(selected);
    setIsEditModalOpen(true);
  };

  const handleAddUser = () => {
    setIsAddUserModalOpen(true);
  };

  const handleSave = async (updatedUser) => {
    try {
      // Send PUT request to update user
      const response = await axios.put(
        `${BaseURL}/editUser`,
        updatedUser
      );
      if (
        response.status === 200 &&
        response.data.message === "Name must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }
      // Assuming your backend returns updated user details, handle the response
      // Check for successful update
      if (
        response.status === 200 &&
        response.data.message === "User details updated successfully"
      ) {
        // Update the usersArray state accordingly
        alert(response.data.message)
        const updatedUsers = usersArray.map((user) =>
          user.id === updatedUser.id ? { ...user, ...updatedUser } : user
        );
        setUsersArray(updatedUsers);
        console.log(usersArray,"usersArray in frontend");
        setIsEditModalOpen(false); // Close the edit modal
      } else {
        console.error("Error updating user:", response.data.message, "1st");
      }
    } catch (error) {
      console.error("Error updating user:", error, "2nd");
    }
  };

  const handleDeleteModalOpen = (userId) => {
    const selected = usersArray.find((user) => user.id === userId);
    setSelectedUser(selected);
    setIsDeleteModalOpen(true);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>User Management</h4>
                    <button
                      className="btn btn-primary rounded"
                      onClick={handleAddUser}
                    >
                      Add User
                    </button>
                  </div>
                  <AddUserModal
                    isOpen={isAddUserModalOpen}
                    onClose={() => setIsAddUserModalOpen(false)}
                    setUsersArray={setUsersArray}
                  />

                  <Userstable
                    usersArray={usersArray}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteModalOpen}
                    setUsersArray={setUsersArray}
                  />
                  <div>
                    <EditUserModal
                      isOpen={isEditModalOpen}
                      setIsEditModalOpen={setIsEditModalOpen}
                      onClose={() => setIsEditModalOpen(false)}
                      user={selectedUser}
                      handleSave={handleSave}
                    />
                    <DeleteModal
                      isOpen={isDeleteModalOpen}
                      setIsOpen={setIsDeleteModalOpen}
                      user={selectedUser}
                      handleDelete={async (userId) => {
                        try {
                          const response = await axios.delete(
                            `${BaseURL}/deleteUser/${userId}`
                          );
                          if (response.status === 201 && response.data.message==="User deleted successfully") {
                            const updatedUsers = usersArray.filter(
                              (user) => user.id !== userId
                            );
                            setUsersArray(updatedUsers);
                            setIsDeleteModalOpen(false);
                          } else {
                            console.error(
                              "Error deleting user:",
                              response.data.message
                            );
                          }
                        } catch (error) {
                          console.error("Error deleting user:", error);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div> */}

              {/* </div> */}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
