import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function Propertiestable({ propertiesArray, handleEdit, handleDelete }) {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "state", headerName: "State", width: 130 },
    { field: "country", headerName: "Country", width: 130 },
    { field: "price", headerName: "Price", type: "number", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width:"100%",
            height:"100%"
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={propertiesArray} // Use staffsArray directly as rows
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Propertiestable;
