// import logo from './logo.svg';
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import Staffs from "./components/staffs/Staffs";
import Settings from "./components/settings/Settings";
import Login from "./components/login/Login";
import Profile from "./components/profile/Profile";
import Register from "./components/register/Register";
import Properties from "./components/properties/Properties";
import RegisterProperty from "./components/properties/RegisterProperty";
import EditProperty from "./components/properties/EditProperty";
import Users from './components/users/Users';
import UserProfile from "./components/users/UserProfile";
import LoadProperties from "./components/users/LoadProperties";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/staffs" element={<Staffs />} />
          <Route path="/users" element={<Users/>}/>
          <Route path="/properties" element={<Properties />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/usersprofile" element={<UserProfile/>}/>
          <Route path="/register" element={<Register />} />
          <Route path="/registerProperty" element={<RegisterProperty />} />
          <Route path="/editProperty/:propertyId" element={<EditProperty />} />
          {/* <Route path="/loadproperties" element={<LoadProperties/>}/> */}
          <Route path="/loadproperties/" element={<LoadProperties />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
