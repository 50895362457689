export const isValidMobile = (mobile) => {
  const mobileRegex = /^\d{10}$/;
  return mobileRegex.test(mobile) || mobile === "";
};

export const isValidPrice = (price) => {
  // Convert the price to a number
  const numericPrice = parseFloat(price);

  // Check if the price is a valid number and greater than 5000
  return !isNaN(numericPrice) && numericPrice > 5000;
};

export const isValidSquareFeet=(sqft)=>{
  const numericSquareFeet=parseFloat(sqft)
  return !isNaN(numericSquareFeet)&&numericSquareFeet>=100
}

export const isValidIndianPIN=(pin)=> {
  // Regular expression to match exactly 6 digits
  const pinRegex = /^\d{6}$/;

  // Check if the provided PIN matches the regex pattern
  return pinRegex.test(pin);
}

export const isValidDate = (date) => {
  const currentDate = new Date(); // Get the current date and time

  // Convert the provided date string to a Date object for comparison
  const inputDate = new Date(date);

  // Check if the input date is a valid date and is in the future
  if (inputDate instanceof Date && !isNaN(inputDate) && inputDate > currentDate) {
    return true; // Valid date and in the future
  } else {
    return false; // Invalid date or not in the future
  }
};

