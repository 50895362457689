import React, { useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  isValidDate,
  isValidIndianPIN,
  isValidPrice,
  isValidSquareFeet,
} from "../../utils/validations.js";
import { BaseURL } from "../common/BaseUrl.js";

function RegisterProperty() {
  const navigate = useNavigate();

  const [propertyDetails, setPropertyDetails] = useState({
    title: "",
    description: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    price: "",
    property_type: -1,
    available_from: null,
    property_status: -1,
    // property_for: -1,
    sqft: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      available_from: date,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (propertyDetails.property_type == -1) {
      alert("Please select a valid property type");
      return;
    }

    if (propertyDetails.property_status == -1) {
      alert("Please select a valid property status.");
      return;
    }
    // Handle form submission (e.g., send userDetails to backend)
    if (!isValidIndianPIN(propertyDetails.zipcode)) {
      alert("Please enter a valid 6-digit zipcode");
      return;
    }

    if (!isValidPrice(propertyDetails.price)) {
      alert("Please enter a price greater than 5000");
      return;
    }

    if (!isValidDate(propertyDetails.available_from)) {
      alert("Please select future date");
      return;
    }

    if (!isValidSquareFeet(propertyDetails.sqft)) {
      alert("Please enter an area of atleast 100 squarefeet");
    }

    // console.log("Form submitted with details:", propertyDetails);
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    try {
      const response = await axios.post(
        `${BaseURL}/registerProperty`,
        propertyDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token to Authorization header
            Role: role, // Attach role to Role header
          },
        }
      );
      // console.log(response,"-----------------response------------------");
      if (response.status === 200) {
        if (response.data.message === "Access not allowed") {
          alert("Access not allowed. Please check your permissions.");
          return;
        }

        if (
          response.data.message === "title must be at least 4 characters long"
        ) {
          alert(response.data.message);
          return;
        }

        if (
          response.data.message === "address must be at least 4 characters long"
        ) {
          alert(response.data.message);
          return;
        }

        if (
          response.data.message === "state must be at least 4 characters long"
        ) {
          alert(response.data.message);
          return;
        }

        if (
          response.data.message === "country must be at least 4 characters long"
        ) {
          alert(response.data.message);
          return;
        }

        if (
          response.data.message ===
          "description must be at least 10 characters long"
        ) {
          alert(response.data.message);
          return;
        }
      }

      if (
        response.status === 201 &&
        response.data.message === "Property registered successfully"
      ) {
        const propertyId = response.data.propertyId;
        console.log(propertyId, "---------------propertyId----------------");
        alert(response.data.message);
        console.log(response, "-----------------response------------------");
        // Navigate to editProperty route with propertyId as URL parameter
        navigate(`/editProperty/${propertyId}?redirectFrom=register`);
      } else {
        alert("Failed to add property", "-------1st----------");
      }
    } catch (error) {
      console.error("Error adding property:", error);
      alert("Failed to add property", "-------2nd----------");
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Add Property</h4>
                  <form
                    className="forms-sample"
                    style={{ marginTop: "30px" }}
                    onSubmit={handleSubmit}
                  >
                    <div>
                      {/* Form fields for initial registration */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              name="title"
                              value={propertyDetails.title}
                              onChange={handleChange}
                              placeholder="Title"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                              className="form-control"
                              id="description"
                              name="description"
                              value={propertyDetails.description}
                              onChange={handleChange}
                              placeholder="Description"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="address">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              name="address"
                              value={propertyDetails.address}
                              onChange={handleChange}
                              placeholder="Address"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              value={propertyDetails.city}
                              onChange={handleChange}
                              placeholder="City"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="state">State</label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              value={propertyDetails.state}
                              onChange={handleChange}
                              placeholder="State"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              value={propertyDetails.country}
                              onChange={handleChange}
                              placeholder="Country"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="property_type">Property Type</label>
                            <select
                              className="form-select"
                              id="property_type"
                              name="property_type"
                              value={propertyDetails.property_type}
                              onChange={handleChange}
                              required
                            >
                              <option value={-1}>Select</option>
                              <option value={0}>Residential</option>
                              <option value={1}>Commercial</option>
                              <option value={2}>Plot</option>
                            </select>
                          </div>
                          {/* Date of Availability Field */}
                          <div
                            className="form-group"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label htmlFor="available_from">
                              Date of Availability
                            </label>
                            <DatePicker
                              className="form-control"
                              id="available_from"
                              name="available_from"
                              selected={propertyDetails.available_from}
                              onChange={handleDateChange}
                              placeholderText="Select Date"
                              dateFormat="dd/MM/yyyy"
                              required
                            />
                          </div>

                          {/* 1st Image----------------- */}
                          {/* File Upload Field */}
                          {/* <div className="form-group">
                         <label htmlFor="bannerImage">
                            Banner Image upload
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="bannerImage"
                              name="bannerImage"
                              className="file-upload-default"
                              onChange={handleFileChange}
                            />
                            {/* Display the selected file name */}
                          {/* <input
                              type="text"
                              className="form-control file-upload-info"
                              value={
                                propertyDetails.bannerImage
                                  ? propertyDetails.bannerImage.name
                                  : ""
                              }
                              placeholder="Upload Image"
                              disabled
                            />
                            <span className="input-group-append">
                              <button
                                className="file-upload-browse btn btn-primary"
                                type="button"
                              >
                                Upload
                              </button>
                            </span> */}
                          {/* </div> 
                        </div>  */}

                          {/* 2nd Image----------------- */}
                          {/* File Upload Field */}
                          {/* <div className="form-group">
                          <label>Images upload</label>
                          <div className="input-group">
                            <input
                              type="file"
                              name="img[]"
                              className="file-upload-default"
                              aria-describedby="file-upload-addon"
                            />
                            <input
                              type="text"
                              className="form-control file-upload-info"
                              disabled
                              placeholder="Upload Image"
                            />
                            <span className="input-group-append">
                              <button
                                className="file-upload-browse btn btn-primary"
                                type="button"
                              >
                                Upload
                              </button>
                            </span>
                          </div>
                        </div> */}
                          <div className="form-group">
                            <label htmlFor="property_status">
                              Property Status
                            </label>
                            <select
                              className="form-select"
                              id="property_status"
                              name="property_status"
                              value={propertyDetails.property_status}
                              onChange={handleChange}
                            >
                              <option value={-1}>Select</option>
                              <option value={0}>Ready</option>
                              <option value={1}>Under Construction</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="sqft">Square Feet</label>
                            <input
                              type="text"
                              value={propertyDetails.sqft}
                              onChange={handleChange}
                              className="form-control"
                              id="sqft"
                              name="sqft"
                              placeholder="SquareFeet"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="price">Price</label>
                            <input
                              type="text"
                              className="form-control"
                              id="price"
                              name="price"
                              onChange={handleChange}
                              value={propertyDetails.price}
                              placeholder="Price"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="zipcode">Zipcode</label>
                            <input
                              type="text"
                              className="form-control"
                              name="zipcode"
                              id="zipcode"
                              value={propertyDetails.zipcode}
                              onChange={handleChange}
                              placeholder="Zipcode"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Submit and Cancel Buttons */}
                      <button type="submit" className="btn btn-primary me-2">
                        Submit
                      </button>
                      <button className="btn btn-light">Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterProperty;

// const handleFileChange = (e) => {
//   const file = e.target.files[0];
//   const name = e.target.name;

//   if (file) {
//     const reader = new FileReader();
//     reader.onload = () => {
//       // Set image preview based on the file being uploaded
//       setPropertyDetails((prevDetails) => ({
//         ...prevDetails,
//         [name]: reader.result, // Assuming single file upload
//       }));
//     };
//     reader.readAsDataURL(file);
//   }
// };
