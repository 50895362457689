import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            {/* <i className="mdi mdi-grid-large menu-icon" /> */}
            {/* <i className="mdi mdi-view-dashboard menu-icon" /> */}
            {/* <i className="mdi mdi-view-grid menu-icon" /> */}
            <i className="mdi mdi-chart-bar menu-icon" />
            {/* <i class="fa-solid fa-gauge-high"></i> */}
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/staffs">
            {/* <i className="mdi mdi-grid-large menu-icon" /> */}
            {/* <i className="mdi mdi-account-multiple menu-icon" /> */}
            <i className="bi bi-person-add" style={{ fontSize: '24px', marginRight:"10px" }}></i>
            <span className="menu-title">Staffs</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/properties">
            {/* <i className="mdi mdi-grid-large menu-icon" /> */}
            {/* <i className="mdi mdi-house menu-icon" /> */}
            {/* <i className="mdi mdi-domain menu-icon" /> */}
            <i className="mdi mdi-home menu-icon" />
            <span className="menu-title">Properties</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/users">
            {/* <i className="mdi mdi-grid-large menu-icon" /> */}
            {/* <i className="mdi mdi-account-multiple menu-icon" /> */}
            <i className="fa fa-users" style={{ fontSize: '24px', marginRight:"10px" }}></i> 
            <span className="menu-title">Users</span>
          </Link>
        </li>
        {/* <li className="nav-item"> */}
          {/* <Link className="nav-link" to="/brokers"> */}
            {/* <i className="mdi mdi-grid-large menu-icon" /> */}
            {/* <i className="mdi mdi-account-multiple menu-icon" /> */}
            {/* <i className="fa fa-users" style={{ fontSize: '24px', marginRight:"10px" }}></i> 
            <span className="menu-title">Brokers</span> */}
          {/* </Link> */}
        {/* </li> */}
      </ul>
    </nav>
  );
}
