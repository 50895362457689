import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";
import axios from "axios";
import Staffstable from "./Staffstable";
import EditStaffModal from "./EditStaffModal";
import DeleteModal from "./DeleteModal";
import AddStaffModal from "./AddStaffModal";
import { BaseURL } from "../common/BaseUrl";

export default function Staffs() {
  const [staffsArray, setStaffsArray] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage or session storage
      const response = await axios.get(
        `${BaseURL}/getAllStaffsExceptLoggedInStaff`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token to Authorization header
          },
        }
      );
      try {
        if (
          response.status === 200 &&
          response.data.message === "Other Staff Details Collected"
        ) {
          setStaffsArray(response.data.staffs);
        }
        if (
          response.status === 200 &&
          response.data.message === "No other staffs found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        if (
          response.status === 500 &&
          response.data.message === "Internal server error"
        ) {
          alert(response.data.message);
        }
        console.error("Error fetching staffs:", error);
      }
    };

    fetchData();
  }, [staffsArray]);

  const handleEdit = (staffId) => {
    const selected = staffsArray.find((staff) => staff.id === staffId);
    setSelectedStaff(selected);
    setIsEditModalOpen(true);
  };

  const handleAddStaff = () => {
    setIsAddStaffModalOpen(true);
  };

  const handleSave = async (updatedStaff) => {
    try {
      // Send PUT request to update staff
      const response = await axios.put(
        `${BaseURL}/editStaff`,
        updatedStaff
      );
      if (
        response.status === 200 &&
        response.data.message === "Name must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }
      if (
        response.status === 200 &&
        response.data.message === "Address must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }
      // Assuming your backend returns updated staff details, handle the response
      if (
        response.status === 201 &&
        response.data.message === "Staff details updated successfully"
      ) {
        // Update the staffsArray state accordingly
        const updatedStaffs = staffsArray.map((staff) =>
          staff.id === updatedStaff.id ? { ...staff, ...updatedStaff } : staff
        );
        setStaffsArray(updatedStaffs);
        setIsEditModalOpen(false); // Close the edit modal
      } else {
        console.error("Error updating staff:", response.data.message, "1st");
      }
    } catch (error) {
      console.error("Error updating staff:", error, "2nd");
    }
  };

  const handleDeleteModalOpen = (staffId) => {
    const selected = staffsArray.find((staff) => staff.id === staffId);
    setSelectedStaff(selected);
    setIsDeleteModalOpen(true);
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px"
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Staff Management</h4>
                    <button
                      className="btn btn-primary rounded"
                      onClick={handleAddStaff}
                    >
                      Add Staff
                    </button>
                  </div>
                  <AddStaffModal
                    isOpen={isAddStaffModalOpen}
                    onClose={() => setIsAddStaffModalOpen(false)}
                    setStaffsArray={setStaffsArray}
                  />

                  <Staffstable
                    staffsArray={staffsArray}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteModalOpen}
                  />
                  <div>
                    <EditStaffModal
                      isOpen={isEditModalOpen}
                      setIsEditModalOpen={setIsEditModalOpen}
                      onClose={() => setIsEditModalOpen(false)}
                      staff={selectedStaff}
                      handleSave={handleSave}
                    />
                    <DeleteModal
                      isOpen={isDeleteModalOpen}
                      setIsOpen={setIsDeleteModalOpen}
                      staff={selectedStaff}
                      handleDelete={async (staffId) => {
                        try {
                          const response = await axios.delete(
                            `${BaseURL}/deleteStaff/${staffId}`
                          );
                          if (response.status === 200) {
                            const updatedStaffs = staffsArray.filter(
                              (staff) => staff.id !== staffId
                            );
                            setStaffsArray(updatedStaffs);
                            setIsDeleteModalOpen(false);
                          } else {
                            console.error(
                              "Error deleting staff:",
                              response.data.message
                            );
                          }
                        } catch (error) {
                          console.error("Error deleting staff:", error);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div> */}

              {/* </div> */}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
