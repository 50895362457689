import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isValidMobile } from "../../utils/validations";
import axios from "axios";
import { BaseURL } from "../common/BaseUrl";

function Register() {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // console.log("Edited Staff Details:", editedDetails);
      if (!isValidMobile(userDetails.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }
      const response = await axios.post(`${BaseURL}/register`, {
        name: userDetails.name,
        email: userDetails.email,
        mobile: userDetails.mobile,
        address: userDetails.address,
        password: userDetails.password,
      });

      if (
        response.status === 200 &&
        response.data.message === "Username must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "address must be at least 4 characters long"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "Please enter a valid email address"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "Please enter a valid mobile number"
      ) {
        alert(response.data.message);
      }

      if (
        response.status === 200 &&
        response.data.message === "Password must be at least 6 characters long"
      ) {
        alert(response.data.message);
      }

      if (response.status === 201) {
        alert("Staff registered successfully!");
        navigate("/staffs"); // Redirect to home page after successful registration
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        alert("User with this email already exists!");
      } else {
        alert("Failed to register user. Please try again later.");
      }
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h3 className="card-title text-center mb-4">Sign Up</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    value={userDetails.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Mobile"
                    value={userDetails.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    value={userDetails.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={userDetails.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3 form-check" style={{display:"flex", justifyContent:"center"}}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreeTerms"
                    required
                  />
                  <label htmlFor="agreeTerms" className="form-check-label">
                    I agree to all Terms & Conditions
                  </label>
                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign Up
                  </button>
                </div>
              </form>
              <p className="text-center mt-3">
                Already have an account? <Link to="/">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
