import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import UITheam from "../common/UITheam";
import profileImage from "../assets/images/faces/face8.jpg";
import axios from "axios";
import { BaseURL } from "../common/BaseUrl";
import { useLocation } from "react-router-dom";
import queryString from "query-string"; // Import queryString library

const propertyTypeMap = {
  "-1": "Select",
  0: "Residential",
  1: "Commercial",
  2: "Plot",
};

const propertyStatusMap = {
  "-1": "Select",
  0: "Ready",
  1: "Under Construction",
};

const parkingSpaceMap = {
  "-1": "Select",
  0: "2 Wheeler",
  1: "4 Wheeler",
  2: "Both",
  3: "No Parking Space",
};

const bhkTypeMap = {
  "-1": "Select",
  0: "1BHK",
  1: "2BHK",
  2: "3BHK",
  3: "4BHK",
  4: "5BHK",
  5: "+5BHK",
};

const residentialTypeMap = {
  "-1": "Select",
  0: "Apartment",
  1: "Independent House/Villa",
  2: "Gated Community Villa",
};

const commertialTypeMap = {
  "-1": "Select",
  0: "Office Space",
  1: "Shop/Showroom",
  2: "Warehouse/Godown",
  3: "Industrial Building",
};

const furnishingTypeMap = {
  "-1": "Select",
  0: "Fully Furnished",
  1: "Semi Furnished",
  2: "Non Furnished",
};

const propertyForMap = {
  "-1": "Select",
  0: "Rent",
  1: "Sell",
  2: "Lease",
};

const preferedTenantsMap = {
  "-1": "Select",
  0: "Anyone",
  1: "Bachelor Male",
  2: "Bachelor Female",
  3: "Family",
};

const boundaryWallMap = {
  "-1": "Select",
  0: "Yes",
  1: "No",
};

const waterSupplyMap = {
  "-1": "Select",
  0: "Corporation",
  1: "Borewell",
  2: "Both",
};

const availableVisitingDaysMap = {
  "-1": "Select",
  0: "Everyday (Mon-Sun)",
  1: "Weekdays (Mon-Fri)",
  2: "Weekends (Sat, Sun)",
};

const amenitiesMap = {
  0: "Lift",
  1: "AC",
  2: "Children's Play Area",
  3: "Visitor Parking",
};

function LoadProperties() {
  const location = useLocation();


  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1); // Add pageSize state
  const [totalCount, setTotalCount] = useState(0); // Add totalCount state

  useEffect(() => {
    const fetchAllProperties = async () => {
      try {
        const response = await axios.get(
          `${BaseURL}/getAllPropertiesForUser?page=${currentPage}&pageSize=${pageSize}`
        );
        setProperties(response.data.properties);
        setTotalCount(response.data.totalCount); // Set total count from the response
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    fetchAllProperties();
  }, [currentPage, pageSize]);

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const formatAmenities = (amenities) => {
    if (!amenities) return "N/A";
    const amenityList = JSON.parse(amenities);
    return amenityList.map((a) => amenitiesMap[a]).join(", ");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const filterFields = (property) => {
    const fields = [
      { label: "Type", value: propertyTypeMap[property.property_type] },
      { label: "Status", value: propertyStatusMap[property.property_status] },
      { label: "BHK Type", value: bhkTypeMap[property.bhk_type] },
      {
        label: "Residential Type",
        value: residentialTypeMap[property.residential_type],
      },
      {
        label: "Commercial Type",
        value: commertialTypeMap[property.commertial_type],
      },
      {
        label: "Furnishing Type",
        value: furnishingTypeMap[property.furnishing_type],
      },
      { label: "Property For", value: propertyForMap[property.property_for] },
      {
        label: "Preferred Tenants",
        value: preferedTenantsMap[property.prefered_tenants],
      },
      {
        label: "Boundary Wall",
        value: boundaryWallMap[property.boundary_wall],
      },
      { label: "Water Supply", value: waterSupplyMap[property.water_supply] },
      {
        label: "Parking Space",
        value: parkingSpaceMap[property.parking_space],
      },
      {
        label: "Available Visiting Days",
        value: availableVisitingDaysMap[property.available_visiting_days],
      },
      { label: "Amenities", value: formatAmenities(property.amenities) },
    ];

    return fields.filter((field) => field.value !== "Select");
  };

  const handleWishlist = async (propertyId, userId) => {
    try {
      const response = await axios.post(`${BaseURL}/add-to-favorites`, {
        customerId: userId,
        propertyId: propertyId,
      });
      console.log("Property added to favorites:", response.data);
    } catch (error) {
      console.error("Error adding property to favorites:", error);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <h2 style={{ margin: "25px" }}>Properties List</h2>
                <div className="card-body">
                  {properties.length > 0 ? (
                    properties.map((property) => (
                      <div
                        className="card mb-3"
                        key={property.id}
                        style={{
                          border: "2px solid black",
                        }}
                      >
                        <div className="card-header">
                          <h5 className="card-title">{property.title}</h5>
                          <p className="card-text">{property.description}</p>
                        </div>
                        <div className="card-body">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="card-text">Rent: ₹{property.price}</p>
                            <p className="card-text">Sqft: {property.sqft}</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="row">
                            <div className="col">
                              <img
                                src={profileImage}
                                alt="Property"
                                style={{ width: "150px", height: "auto" }}
                              />{" "}
                              {/* Adjust image size here */}
                            </div>
                            <div className="col">
                              <p className="card-text">
                                Furnishing Type:
                                {furnishingTypeMap[property.furnishing_type]}
                              </p>
                              <p className="card-text">
                                BHK Type: {bhkTypeMap[property.bhk_type]}
                              </p>
                            </div>
                            <div className="col">
                              <p className="card-text">
                                Preferred Tenants:
                                {preferedTenantsMap[property.prefered_tenants]}
                              </p>
                              <p className="card-text">
                                Available From:{" "}
                                {formatDate(property.available_from)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "50px",
                          }}
                        >
                          <button
                            className="btn btn-primary"
                            onClick={handleWishlist()}
                          >
                            Wishlist
                          </button>
                          <button className="btn btn-success">
                            Get Owner Details
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No properties found</p>
                  )}
                </div>

                {totalPages > 1 && (
                  <div
                    className="pagination"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button
                      onClick={handlePrevPage}
                      className="btn btn-primary"
                      disabled={currentPage === 1}
                      style={{ marginRight: "20px" }}
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextPage}
                      className="btn btn-primary"
                      disabled={currentPage === totalPages}
                      style={{ marginLeft: "20px" }}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadProperties;
