import { STAFF_INFO,CLEAR_STAFF_INFO } from '../types/staffTypes';

const initialState = {
  staffDetails: null,
};

const staffReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case STAFF_INFO:
      return {
        ...state,
        staffDetails: action.payload,
      };
      case CLEAR_STAFF_INFO:
        return {
          ...state,
          staffDetails: null,
        };
    default:
      return state;
  }
};

export default staffReducer;
