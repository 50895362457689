import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import axios from "axios";
import Propertiestable from "./Propertiestable";
import DeletePropertyModal from "./DeletePropertyModal";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../common/BaseUrl";

function Properties() {
  const [propertiesArray, setPropertiesArray] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isDeletePropertyModalOpen, setIsDeletePropertyModalOpen] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${BaseURL}/getAllproperties`
      );
      try {
        if (
          response.status === 201 &&
          response.data.message === "Property Details collected successfully"
        ) {
          setPropertiesArray(response.data.properties);
        }
        if (
          response.status === 200 &&
          response.data.message === "No properties found"
        ) {
          alert(response.data.message);
        }
      } catch (error) {
        if (
          response.status === 500 &&
          response.data.message === "Failed to fetch properties"
        ) {
          alert(response.data.message);
        }
        console.error("Error fetching properties:", error);
      }
    };

    fetchData();
  }, [propertiesArray]);

  const handleEdit = (propertyId) => {
    const selected = propertiesArray.find(
      (property) => property.id === propertyId
    );
    if (selected) {
      setSelectedProperty(selected);
      navigate(`/editProperty/${propertyId}`); // Navigate using the propertyId directly
    } else {
      console.error(`Property with ID ${propertyId} not found.`);
    }
  };

  const handleAddProperty = () => {
    navigate("/registerProperty");
  };

  const handleDeletePropertyModalOpen = (propertyId) => {
    const selected = propertiesArray.find(
      (property) => property.id === propertyId
    );
    setSelectedProperty(selected);
    setIsDeletePropertyModalOpen(true);
  };
  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              {/* <div> */}
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h4 style={{ marginTop: "0" }}>Property Management</h4>
                    <button
                      className="btn btn-primary rounded"
                      onClick={handleAddProperty}
                    >
                      Add Property
                    </button>
                  </div>
                  <Propertiestable
                    propertiesArray={propertiesArray}
                    handleEdit={handleEdit}
                    handleDelete={handleDeletePropertyModalOpen}
                  />
                  <div>
                    <DeletePropertyModal
                      isOpen={isDeletePropertyModalOpen}
                      setIsOpen={setIsDeletePropertyModalOpen}
                      property={selectedProperty}
                      handleDelete={async (propertyId) => {
                        try {
                          const response = await axios.delete(
                            `${BaseURL}/deleteProperty/${propertyId}`
                          );
                          if (response.status === 200) {
                            const updatedProperties = response.data.properties;
                            setPropertiesArray(updatedProperties);
                            setIsDeletePropertyModalOpen(false);
                          } else {
                            console.error(
                              "Error deleting property:",
                              response.data.message
                            );
                          }
                        } catch (error) {
                          console.error("Error deleting staff:", error);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Properties;
