import React, { useEffect, useState } from "react";
import { isValidMobile } from "../../utils/validations";

function EditUserModal({
  isOpen,
  setIsEditModalOpen,
  onClose,
  user,
  handleSave,
}) {
  const [editedDetails, setEditedDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  // Update editedDetails when user prop changes
  useEffect(() => {
    if (user) {
      setEditedDetails({
        name: user.name || "",
        email: user.email || "",
        mobile: user.mobile || "",
      });
    }
  }, [user]);

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  const handleSubmit = () => {
    // Validation and saving logic
    if (!isValidMobile(editedDetails.mobile)) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }
    handleSave(editedDetails); // Call your save function with updated details
    setIsEditModalOpen(false); // Close the modal after saving
  };
  return (
    <div
      className={`modal ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Staff Details</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#000",
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedDetails.email}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={editedDetails.name}
                  name="name"
                  onChange={handleUserDetailsChange}
                />
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile"
                  value={editedDetails.mobile}
                  name="mobile"
                  onChange={handleUserDetailsChange}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserModal;
