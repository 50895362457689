import React, { useEffect, useState, useRef } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
  isValidDate,
  isValidIndianPIN,
  isValidPrice,
  isValidSquareFeet,
} from "../../utils/validations";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BaseURL } from "../common/BaseUrl";

function EditProperty() {
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyId } = useParams(); // Extract propertyId from URL params
  console.log(propertyId, "from params..........................");

  const [propertyDetails, setPropertyDetails] = useState({
    title: "",
    description: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    price: "",
    property_type: -1,
    available_from: null,
    property_status: -1,
    parking_space: -1,
    property_for: -1,
    sqft: "",
    boundary_wall: -1,
    prefered_tenants: -1,
    residential_type: -1,
    commertial_type: -1,
    water_supply: -1,
    furnishing_type: -1,
    bhk_type: -1,
    available_visiting_days: -1,
    amenities: [], // Initialize amenities as an empty array
  });
  const bannerInputRef = useRef(null);
  const imagesArrayInputRef = useRef(null);
  const [propertyTab, setPropertyTab] = useState(0);
  const [tabCompletion, setTabCompletion] = useState([false, false, false]);
  const [isModified, setIsModified] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [imagesArray, setImagesArray] = useState([]);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [imagesArrayPreviews, setImagesArrayPreviews] = useState([]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const role = localStorage.getItem("role");

        const response = await axios.get(
          `${BaseURL}/findProperty/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Role: role,
            },
          }
        );

        if (response.status === 201) {
          const fetchedPropertyDetails = response.data.property;
          setPropertyDetails(fetchedPropertyDetails);
          console.log(
            propertyDetails,
            "propertyDetails------------in Frontend"
          );
        }
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchPropertyDetails();
    const urlParams = new URLSearchParams(location.search).get("redirectFrom");
    if (urlParams === "register") {
      setPropertyTab(1);
      setTabCompletion([true, false, false]);
    }
  }, [propertyId]);

  const handleAmenityChange = (e) => {
    if (!propertyDetails) return; // Check if propertyDetails is null or undefined
    const { value, checked } = e.target;
    // Ensure amenities is always initialized as an array
    const updatedAmenities = propertyDetails.amenities
      ? [...propertyDetails.amenities]
      : [];

    if (checked) {
      // Add amenity if checked
      updatedAmenities.push(value);
    } else {
      // Remove amenity if unchecked
      const index = updatedAmenities.indexOf(value);
      if (index !== -1) {
        updatedAmenities.splice(index, 1);
      }
    }

    setPropertyDetails({
      ...propertyDetails,
      amenities: updatedAmenities,
    });
  };

  if (!propertyDetails) {
    // Render loading state or return null if propertyDetails is null or undefined
    return null;
  }

  const handleBannerImageChange = (e) => {
    const file = e.target.files[0];
    setBannerImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBannerImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImagesArrayChange = (e) => {
    const files = Array.from(e.target.files);
    setImagesArray(files);

    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previews).then((imagePreviews) => {
      setImagesArrayPreviews(imagePreviews);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setIsModified(true);
  };
  console.log(
    propertyDetails,
    "---------------propertyDetails--------------------"
  );
  const handleDateChange = (date) => {
    setPropertyDetails((prevDetails) => ({
      ...prevDetails,
      available_from: date,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (propertyDetails.property_type == -1) {
      alert("Please select a valid property type");
      return;
    }
    if (propertyDetails.property_status == -1) {
      alert("Please select a valid property status.");
      return;
    }

    if (!isValidIndianPIN(propertyDetails.zipcode)) {
      alert("Please enter a valid 6-digit zipcode");
      return;
    }

    if (!isValidSquareFeet(propertyDetails.sqft)) {
      alert("Please enter an area of atleast 100 squarefeet");
    }

    if (!isValidDate(propertyDetails.available_from)) {
      alert("Please select future date");
      return;
    }

    if (!isValidPrice(propertyDetails.price)) {
      alert("Please enter a price greater than 5000");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseURL}/editProperty/${propertyId}`,
        propertyDetails
      );

      if (
        response.status === 201 &&
        response.data.message === "Property details updated successfully"
      ) {
        // alert(response.data.message);
        // setPropertyDetails(response.data.property);
        // Optionally, you can add a success message or redirect to another page upon successful update
        // navigate(`/property/${propertyId}`); // Redirect to the property details page
        // navigate("/properties");
        alert(response.data.message);
        setPropertyDetails(response.data.property);
        setTabCompletion((prevCompletion) =>
          prevCompletion.map((completed, index) =>
            index === propertyTab ? true : completed
          )
        );
        setIsModified(false);
      } else {
        console.error("Error updating property:", response.data.message);
        // Handle other error scenarios or display appropriate messages to the user
      }
    } catch (error) {
      console.error("Error updating property:", error);
      // Handle network errors or other exceptions that occur during the API request
    }
  };

  const handleTabChange = (newTab) => {
    if (isModified) {
      window.alert("Please save your changes before proceeding.");
      return;
    }
    if (newTab > 0 && !tabCompletion[newTab - 1]) {
      alert("Please complete the previous tab before proceeding.");
      return;
    }
    setPropertyTab(newTab);
  };

  console.log(
    propertyDetails.property_type,
    "---------------propertyDetails.property_type---------"
  );

  const handleSubmitAdditionalInformation = async (event) => {
    event.preventDefault();

    let message="Please select the following:\n";
    if (propertyDetails.property_type == 0 && propertyDetails.bhk_type == -1) {
      message="BHK type\n"
    }
    if (propertyDetails.property_for == -1) {
      message+="property Purpose option\n"
    }
    if (
      propertyDetails.property_type != 2 &&
      propertyDetails.parking_space == -1
    ) {
      message+="parking space option\n"
    }
    if (
      propertyDetails.property_type == 2 &&
      propertyDetails.boundary_wall == -1
    ) {
      message+=",boundary wall option\n"
    }
    if (
      propertyDetails.property_type == 0 &&
      propertyDetails.residential_type == -1
    ) {
      // alert("Please select a valid Residential type");
      // return;
      message+="Residential type option\n"
    }

    if (
      propertyDetails.property_type != 2 &&
      propertyDetails.property_for != 1 &&
      propertyDetails.prefered_tenants == -1
    ) {
      message+="Preferred tenants\n"
    }
    if (
      propertyDetails.property_type != 2 &&
      propertyDetails.furnishing_type == -1
    ) {
      message+="Furnishing type\n"
    }
    if (propertyDetails.water_supply == -1) {
      message+="Water supply option\n";
    }

    if (
      propertyDetails.property_type == 1 &&
      propertyDetails.commertial_type == -1
    ) {
      message+="commertial type option\n"
    }
    if (propertyDetails.available_visiting_days == -1) {
      // alert("Please select the available visiting days option");
      // return;
      message+="Available visiting days option"
    }

    if(message!=="Please select the following:\n"){
      alert(message);
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      const response = await axios.post(
        `${BaseURL}/updatePropertyAdditionalInfo/${propertyId}`,
        {
          property_type:propertyDetails.property_type,
          property_for: propertyDetails.property_for,
          parking_space: propertyDetails.parking_space,
          boundary_wall: propertyDetails.boundary_wall,
          prefered_tenants: propertyDetails.prefered_tenants,
          residential_type: propertyDetails.residential_type,
          commertial_type: propertyDetails.commertial_type,
          water_supply: propertyDetails.water_supply,
          furnishing_type: propertyDetails.furnishing_type,
          bhk_type: propertyDetails.bhk_type,
          available_visiting_days: propertyDetails.available_visiting_days,
          amenities:propertyDetails.amenities
        },
        // propertyDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Role: role,
          },
        }
      );

      if (response.status === 200) {
        alert("Additional Information updated successfully");
        setTabCompletion((prevCompletion) =>
          prevCompletion.map((completed, index) =>
            index === propertyTab ? true : completed
          )
        );
        setIsModified(false);
      } else {
        console.error(
          "Error updating additional information:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error updating additional information:", error);
    }
  };

  const handleImageSubmition = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (bannerImage) {
      formData.append("banner_image", bannerImage);
    }
    if (imagesArray.length > 0) {
      imagesArray.forEach((image, index) => {
        formData.append(`images_array`, image);
      });
    }

    try {
      const response = await axios.post(`${BaseURL}/uploadImages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Images uploaded successfully", response.data);
    } catch (error) {
      console.error("Error uploading images", error);
    }
  };

  const handleRemoveImage = (index, type) => {
    if (type === "banner") {
      setBannerImage(null);
      setBannerImagePreview(null);
      if (bannerInputRef.current) {
        bannerInputRef.current.value = "";
      }
    } else if (type === "array") {
      const newImagesArray = [...imagesArray];
      newImagesArray.splice(index, 1);
      setImagesArray(newImagesArray);

      const newImagesArrayPreviews = [...imagesArrayPreviews];
      newImagesArrayPreviews.splice(index, 1);
      setImagesArrayPreviews(newImagesArrayPreviews);

      if (imagesArrayInputRef.current) {
        imagesArrayInputRef.current.value = "";
      }
    }
  };

  // Define the amenities with their respective IDs
  const amenitiesList = [
    { id: 0, name: "Lift" },
    { id: 1, name: "AC" },
    { id: 2, name: "Children's Play Area" },
    { id: 3, name: "Visitor Parking" },
  ];

  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Edit Property</h4>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          propertyTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(0)}
                      >
                        Basic Information
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          propertyTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(1)}
                        disabled={!tabCompletion[0]}
                      >
                        Additional Information
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${
                          propertyTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(2)}
                        disabled={!tabCompletion[1]}
                      >
                        Images
                      </button>
                    </li>
                  </ul>

                  {propertyTab === 0 && (
                    <>
                      <form
                        className="forms-sample"
                        style={{ marginTop: "30px" }}
                        onSubmit={handleSubmit}
                      >
                        <div>
                          {/* Form fields for initial registration */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="title"
                                  name="title"
                                  value={propertyDetails.title}
                                  onChange={handleChange}
                                  placeholder="Title"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                  className="form-control"
                                  id="description"
                                  name="description"
                                  value={propertyDetails.description}
                                  onChange={handleChange}
                                  placeholder="Description"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="address"
                                  value={propertyDetails.address}
                                  onChange={handleChange}
                                  placeholder="Address"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="city"
                                  name="city"
                                  value={propertyDetails.city}
                                  onChange={handleChange}
                                  placeholder="City"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="state">State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="state"
                                  name="state"
                                  value={propertyDetails.state}
                                  onChange={handleChange}
                                  placeholder="State"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="country">Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="country"
                                  name="country"
                                  value={propertyDetails.country}
                                  onChange={handleChange}
                                  placeholder="Country"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="property_type">
                                  Property Type
                                </label>
                                <select
                                  className="form-select"
                                  id="property_type"
                                  name="property_type"
                                  value={propertyDetails.property_type}
                                  onChange={handleChange}
                                >
                                  <option value={-1}>Select</option>
                                  <option value={0}>Residential</option>
                                  <option value={1}>Commercial</option>
                                  <option value={2}>Plot</option>
                                </select>
                              </div>
                              {/* Date of Availability Field */}
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label htmlFor="available_from">
                                  Date of Availability
                                </label>
                                <DatePicker
                                  className="form-control"
                                  id="available_from"
                                  name="available_from"
                                  selected={propertyDetails.available_from}
                                  onChange={handleDateChange}
                                  placeholderText="Select Date"
                                  dateFormat="dd/MM/yyyy"
                                  required
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="exampleSelectGender">
                                  Property Status
                                </label>
                                <select
                                  className="form-select"
                                  id="property_status"
                                  name="property_status"
                                  value={propertyDetails.property_status}
                                  onChange={handleChange}
                                >
                                  <option value={-1}>Select</option>
                                  <option value={0}>Ready</option>
                                  <option value={1}>Under Construction</option>
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="sqft">Square Feet</label>
                                <input
                                  type="text"
                                  value={propertyDetails.sqft}
                                  onChange={handleChange}
                                  className="form-control"
                                  id="sqft"
                                  name="sqft"
                                  placeholder="SquareFeet"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="price">Price</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="price"
                                  name="price"
                                  onChange={handleChange}
                                  value={propertyDetails.price}
                                  placeholder="Price"
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="zipcode">Zipcode</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="zipcode"
                                  id="zipcode"
                                  value={propertyDetails.zipcode}
                                  onChange={handleChange}
                                  placeholder="Zipcode"
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          {/* Submit and Cancel Buttons */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Save & Continue
                          </button>
                          <button className="btn btn-light">Cancel</button>
                        </div>
                      </form>
                    </>
                  )}

                  {propertyTab === 1 && (
                    <>
                      <form
                        className="forms-sample"
                        style={{ marginTop: "30px" }}
                        onSubmit={handleSubmitAdditionalInformation}
                      >
                        <div>
                          {/* Form fields for initial registration */}
                          <div className="row">
                            <div className="col-md-6">
                              {propertyDetails.property_type != 2 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="parking_space">
                                      Parking Space
                                    </label>
                                    <select
                                      className="form-select"
                                      id="parking_space"
                                      name="parking_space"
                                      value={propertyDetails.parking_space}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>2 Wheeler</option>
                                      <option value={1}>4 Wheeler</option>
                                      <option value={2}>
                                        Both 2 Wheeler and 4 Wheeler
                                      </option>
                                      <option value={3}>
                                        No Parking Available
                                      </option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {propertyDetails.property_type != 2 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="property_for">
                                      Property For
                                    </label>
                                    <select
                                      className="form-select"
                                      id="property_for"
                                      name="property_for"
                                      value={propertyDetails.property_for}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>Rent</option>
                                      <option value={1}>Sell</option>
                                      <option value={2}>Lease</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {propertyDetails.property_type == 2 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="property_for">
                                      Property For
                                    </label>
                                    <select
                                      className="form-select"
                                      id="property_for"
                                      name="property_for"
                                      value={propertyDetails.property_for}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={1}>Sell</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {propertyDetails.property_type == 2 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="boundary_wall">
                                      Boundary Wall
                                    </label>
                                    <select
                                      className="form-select"
                                      id="boundary_wall"
                                      name="boundary_wall"
                                      value={propertyDetails.boundary_wall}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>Yes</option>
                                      <option value={1}>No</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {propertyDetails.property_type != 2 &&
                                propertyDetails.property_for != 1 && (
                                  <>
                                    <div className="form-group">
                                      <label htmlFor="prefered_tenants">
                                        Preferred Tenants
                                      </label>
                                      <select
                                        className="form-select"
                                        id="prefered_tenants"
                                        name="prefered_tenants"
                                        value={propertyDetails.prefered_tenants}
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value={-1}>Select</option>
                                        <option value={0}>Anyone</option>
                                        <option value={1}>Bachelor Male</option>
                                        <option value={2}>
                                          Bachelor Female
                                        </option>
                                      </select>
                                    </div>
                                  </>
                                )}

                              {propertyDetails.property_type == 0 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="residential_type">
                                      Residential Property Category
                                    </label>
                                    <select
                                      className="form-select"
                                      id="residential_type"
                                      name="residential_type"
                                      value={propertyDetails.residential_type}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>Apartment</option>
                                      <option value={1}>
                                        Independent House/Villa
                                      </option>
                                      <option value={2}>
                                        Gated Community Villa
                                      </option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {propertyDetails.property_type == 1 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="commertial_type">
                                      Commercial Property Category
                                    </label>
                                    <select
                                      className="form-select"
                                      id="commertial_type"
                                      name="commertial_type"
                                      value={propertyDetails.commertial_type}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>Officespace</option>
                                      <option value={1}>Shop/showroom</option>
                                      <option value={2}>
                                        Warehouse/godown
                                      </option>
                                      <option value={3}>
                                        Industrial Building
                                      </option>
                                    </select>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="col-md-6">
                              {/* Furnishing Type */}
                              {propertyDetails.property_type != 2 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="furnishing_type">
                                      Furnishing Type
                                    </label>
                                    <select
                                      className="form-select"
                                      id="furnishing_type"
                                      name="furnishing_type"
                                      value={propertyDetails.furnishing_type}
                                      onChange={handleChange}
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>Fully Furnished</option>
                                      <option value={1}>Semi Furnished</option>
                                      <option value={2}>Non Furnished</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              <div className="form-group">
                                <label htmlFor="water_supply">
                                  Water Supply
                                </label>
                                <select
                                  className="form-select"
                                  id="water_supply"
                                  name="water_supply"
                                  value={propertyDetails.water_supply}
                                  onChange={handleChange}
                                >
                                  <option value={-1}>Select</option>
                                  <option value={0}>Cooperation</option>
                                  <option value={1}>Borewell</option>
                                  <option value={2}>
                                    Available from Both Cooperation and Borewell
                                  </option>
                                  <option value={3}>
                                    No Water Supply Available Now
                                  </option>
                                </select>
                              </div>
                              {propertyDetails.property_type == 0 && (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="bhk_type">BHK Type</label>
                                    <select
                                      className="form-select"
                                      id="bhk_type"
                                      name="bhk_type"
                                      value={propertyDetails.bhk_type}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value={-1}>Select</option>
                                      <option value={0}>1 BHK</option>
                                      <option value={1}>2 BHK</option>
                                      <option value={2}>3 BHK</option>
                                      <option value={3}>4 BHK</option>
                                      <option value={4}>5 BHK</option>
                                      <option value={5}>+5 BHK</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              <div className="form-group">
                                <label htmlFor="parking_space">
                                  Available Visiting Days
                                </label>
                                <select
                                  className="form-select"
                                  id="available_visiting_days"
                                  name="available_visiting_days"
                                  value={
                                    propertyDetails.available_visiting_days
                                  }
                                  onChange={handleChange}
                                  required
                                >
                                  <option value={-1}>Select</option>
                                  <option value={0}>Everyday(Mon-Sun)</option>
                                  <option value={1}>Weekdays(Mon-Fri)</option>
                                  <option value={2}>Weekends(Sat,Sun)</option>
                                </select>
                              </div>
                            </div>
                            {/* Amenities Section */}
                            {propertyDetails.property_type != 2 && (
                              <div className="form-group">
                                <label>Amenities</label>
                                <div className="d-flex flex-wrap">
                                  {amenitiesList.map((amenity) => (
                                    <div
                                      key={amenity.id}
                                      className="form-check mr-3 mb-2"
                                    >
                                      <div
                                        className="ml-3"
                                        style={{ marginLeft: "40px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`amenity-${amenity.id}`}
                                          value={amenity.id}
                                          checked={
                                            propertyDetails.amenities &&
                                            propertyDetails.amenities.includes(
                                              amenity.id.toString()
                                            )
                                          }
                                          onChange={(e) =>
                                            handleAmenityChange(
                                              e,
                                              amenity.id.toString()
                                            )
                                          }
                                        />
                                      </div>
                                      <div
                                        className="ml-3"
                                        style={{ marginLeft: "20px" }}
                                      >
                                        <label
                                          className="form-check-label ml-1"
                                          htmlFor={`amenity-${amenity.id}`}
                                        >
                                          {amenity.name}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                          {/* Submit and Cancel Buttons */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Save & Continue
                          </button>
                          <button className="btn btn-light">Cancel</button>
                        </div>
                      </form>
                    </>
                  )}

                  {propertyTab === 2 && (
                    <>
                      <form
                        className="forms-sample"
                        style={{ marginTop: "30px" }}
                        onSubmit={handleImageSubmition}
                      >
                        <div className="form-group">
                          <label>Banner Image</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={handleBannerImageChange}
                            ref={bannerInputRef}
                          />
                          {bannerImagePreview && (
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <img
                                src={bannerImagePreview}
                                alt="Banner Preview"
                                style={{
                                  marginTop: "10px",
                                  maxWidth: "200px",
                                  height: "auto",
                                }}
                              />
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveImage(null, "banner")
                                }
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Images Array</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={handleImagesArrayChange}
                            ref={imagesArrayInputRef}
                          />
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px",
                            }}
                          >
                            {imagesArrayPreviews.map((preview, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  src={preview}
                                  alt={`Preview ${index}`}
                                  style={{ maxWidth: "200px", height: "auto" }}
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveImage(index, "array")
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProperty;
