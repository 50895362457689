import React, { useEffect, useState } from "react";
import UITheam from "../common/UITheam";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { isValidMobile } from "../../utils/validations";
import Footer from "../common/Footer";
import { useDispatch } from 'react-redux';
import { STAFF_INFO } from "../../redux/types/staffTypes.js"; 
import { BaseURL } from "../common/BaseUrl.js";


function Profile() {
  const dispatch = useDispatch();
  const token=localStorage.getItem("token");
  const [staffDetails, setStaffDetails] = useState(null);
  const [editedDetails, setEditedDetails] = useState({});


  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    // Function to decode and extract user details from token
    const decodeStaffToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          // Decode the token using jwt-decode library
          const decodedToken = jwtDecode(token);
          console.log(decodedToken, "---------decodedToken---------");
          // Set user details extracted from the token
          setStaffDetails({
            id: decodedToken._id,
            email: decodedToken.email,
            name: decodedToken.name,
            mobile: decodedToken.mobile,
            address: decodedToken.address,
          });
          setEditedDetails({
            email: decodedToken.email,
            name: decodedToken.name,
            mobile: decodedToken.mobile,
            address: decodedToken.address,
          });
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };

    // Call decodeStaffToken when component mounts
    decodeStaffToken();
  }, []);

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(`${BaseURL}/changePassword`, {
      oldPassword: passwordDetails.oldPassword,
      newPassword: passwordDetails.newPassword,
      confirmPassword: passwordDetails.confirmPassword,
      email: staffDetails.email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`, // Attach token to Authorization header
      },
    }
  );
  
  console.log(response.data.staff,"------------------staff at frontend----------------");

    // console.log(response, "response in frontend");
    if (
      response.status === 200 &&
      response.data.message === "Old Password is incorrect"
    ) {
      alert(response.data.message);
    } else if (
      response.status === 200 &&
      response.data.message ===
        "New Password must be at least 6 characters long"
    ) {
      alert(response.data.message);
    } else if (
      response.status === 200 &&
      response.data.message === "newPassword and confirmPassword must be same"
    ) {
      alert(response.data.message);
    } else if (
      response.status === 500 &&
      response.data.message === "Internal server error"
    ) {
      alert(response.data.message);
    } else {
      alert(response.data.message);

    }

    setPasswordDetails({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleCancel = () => {
    setPasswordDetails({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    // console.log(name);
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({
      ...editedDetails,
      [name]: value,
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    // Here you can implement the logic to save editedDetails
    try {
      // console.log("Edited Staff Details:", editedDetails);
      if (!isValidMobile(editedDetails.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }
      const response = await axios.post(
        `${BaseURL}/editstaffdetails`,
        editedDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token to Authorization header
          },
        }
      );
      const {status,data}=response;
      // Handle different response messages
    if (status === 200 && ["Name must be at least 4 characters long", "Please enter a valid mobile number", "Address must be at least 4 characters long"].includes(data.message)) {
      alert(data.message);
      return;
    }

    if (status === 500 && data.message === "Failed to update staff details") {
      alert(data.message);
      return;
    }

    if (status === 201 && data.message === "Staff details updated successfully") {
      alert(data.message);
      setStaffDetails({
        ...staffDetails,
        ...editedDetails,
      });
      dispatch({
        type: STAFF_INFO,
        payload: data.staff,
      });
    }
    } catch (error) {
      console.error("Error editing staff details:", error.message);
    }
  };
  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            {/* <div className="content-wrapper"> */}
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <h2 style={{ marginBottom: "20px" }}>My Profile</h2>
                <h2 style={{ marginBottom: "20px" }}>Change Password</h2>
              </div>

              {/* content-wrapper ends */}
              {/* partial:../../partials/_footer.html */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col-md-5 grid-margin stretch-card mx-5">
                  {staffDetails && (
                    <>
                      <div className="card">
                        <div className="card-body">
                          <form className="forms-sample" onSubmit={handleEdit}>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputUsername2"
                                className="col-sm-3 col-form-label"
                              >
                                Email
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputUsername2"
                                  placeholder={staffDetails.email}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputEmail2"
                                className="col-sm-3 col-form-label"
                              >
                                Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  placeholder="Enter Name"
                                  value={editedDetails.name}
                                  name="name"
                                  onChange={handleUserDetailsChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputMobile"
                                className="col-sm-3 col-form-label"
                              >
                                Mobile
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputMobile"
                                  placeholder="Enter Mobile"
                                  value={editedDetails.mobile}
                                  name="mobile"
                                  onChange={handleUserDetailsChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputMobile"
                                className="col-sm-3 col-form-label"
                              >
                                Address
                              </label>
                              <div className="col-sm-9">
                                <textarea
                                  className="form-control"
                                  id="exampleInputMobile"
                                  placeholder="Enter Address"
                                  value={editedDetails.address}
                                  name="address"
                                  onChange={handleUserDetailsChange}
                                  rows="3" // Set the number of visible rows as needed
                                />
                              </div>
                            </div>
                            <button type="submit" class="btn btn-primary me-2">
                              Edit
                            </button>
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-5 grid-margin stretch-card mx-5">
                  {staffDetails && (
                    <>
                      <div className="card">
                        <div className="card-body">
                          <form
                            className="forms-sample"
                            onSubmit={handlePasswordSubmit}
                          >
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputUsername2"
                                className="col-md-4 col-form-label"
                              >
                                Old Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="exampleInputUsername2"
                                  name="oldPassword"
                                  placeholder="Enter Old Password"
                                  value={passwordDetails.oldPassword}
                                  onChange={handlePasswordChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputEmail2"
                                className="col-md-4 col-form-label"
                              >
                                New Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="exampleInputEmail2"
                                  name="newPassword"
                                  placeholder="Enter New Password"
                                  value={passwordDetails.newPassword}
                                  onChange={handlePasswordChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="exampleInputMobile"
                                className="col-md-4 col-form-label"
                              >
                                Confirm Password
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="exampleInputMobile"
                                  name="confirmPassword"
                                  placeholder="Confirm New Password"
                                  value={passwordDetails.confirmPassword}
                                  onChange={handlePasswordChange}
                                />
                              </div>
                            </div>
                            <button type="submit" class="btn btn-primary me-2">
                              Submit
                            </button>
                            <button
                              onClick={handleCancel}
                              class="btn btn-light"
                            >
                              Cancel
                            </button>
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Footer/>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
