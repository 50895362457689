import React from 'react'
import UITheam from '../common/UITheam'
import Header from '../common/Header'
import Sidebar from '../common/Sidebar'

export default function Settings() {
  return (
    <div>
      <div className="container-scroller">
        <UITheam />
        <Header />

        <div className="container-fluid page-body-wrapper">
          <Sidebar />

          <div className="main-panel">
            <div className="content-wrapper">
              <h1>Settings</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
