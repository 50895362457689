import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { BaseURL } from "../common/BaseUrl";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Staffstable({ staffsArray, handleEdit, handleDelete }) {
  const handleStatusChange = async (id, currentStatus) => {
    // Toggle the status value between 0 and 1
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      // Send the updated staff status to the backend API
      await axios.put(`${BaseURL}/changeStatus?id=${id}&status=${newStatus}`);
      console.log("Status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "mobile", headerName: "Mobile", type: "number", width: 160 },
    { field: "address", headerName: "Address", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={(event) =>
              handleStatusChange(params.row.id, params.row.status)
            }
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger ms-2"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // No need to map staffsArray if rows are directly using staffsArray
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DataGrid
        rows={staffsArray} // Use staffsArray directly as rows
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Staffstable;
