import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../common/BaseUrl";
import { jwtDecode } from "jwt-decode";

function Login() {
  const [staffDetails, setStaffDetails] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const decodeStaffToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          // Decode the token using jwt-decode library
          const decodedToken = jwtDecode(token);
          console.log(decodedToken, "---------decodedToken---------");
          // Set staff details extracted from the token
          setStaffDetails({
            id: decodedToken._id,
            email: decodedToken.email,
            name: decodedToken.name,
            mobile: decodedToken.mobile,
            address: decodedToken.address,
          });
          navigate("/dashboard"); // Redirect to /dashboard route after successful login
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };
    // Call decodeStaffToken when component mounts
    decodeStaffToken();
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStaffDetails({ ...staffDetails, [name]: value });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BaseURL}`, {
        email: staffDetails.email,
        password: staffDetails.password,
      });

      console.log(response, "--------------------");
      const { message } = response.data;
      if (response.status === 200 && message === "Staff not found") {
        alert("Staff not found. Please check your email.");
      } else if (
        response.status === 200 &&
        message === "Password is incorrect"
      ) {
        alert("Password is incorrect. Please try again.");
      } else if (
        response.status === 200 &&
        message === "Please enter a valid email address"
      ) {
        alert("Please enter a valid email address");
      } else if (response.status === 201 && message === "Valid staff") {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        alert("Staff logged in successfully!");
        setStaffDetails(response.data, "--------setStaffDetails-------------");
        navigate("/dashboard"); // Redirect to /dashboard route after successful login
      } else {
        alert("Unexpected response from server.");
      }
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src="../../assets/images/logo.svg" alt="logo" />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 className="fw-light">Sign in to continue.</h6>
                <form onSubmit={handleSubmit} className="pt-3">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Email"
                      onChange={handleChange}
                      value={staffDetails.email}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={handleChange}
                      value={staffDetails.password}
                    />
                  </div>
                  <div className="mt-3 d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  </div>
                </form>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      Keep me signed in
                    </label>
                  </div>
                  <a href="/forgotpassword" className="auth-link text-black">
                    Forgot password?
                  </a>
                </div>
                <div className="text-center mt-4 fw-light">
                  Don't have an account?
                  <a href="/register" className="text-primary">
                    Create
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* content-wrapper ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
  );
}

export default Login;
