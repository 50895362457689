import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { BaseURL } from "../common/BaseUrl";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Switch demo" } };

function Userstable({ usersArray, handleEdit, handleDelete, setUsersArray }) {
  // console.log("usersArray inside table", usersArray);
  const navigate=useNavigate();

  const handleView=(id)=>{
    const user = usersArray.find(user => user.id === id);
    // console.log("Details of user with id:", id, user);
    navigate('/usersprofile', { state: { user } });
  }

  const handleStatusChange = async (id, currentStatus) => {
    // Toggle the status value between 0 and 1
    const newStatus = currentStatus === 1 ? 0 : 1;

    try {
      // Send the updated user status to the backend API
      const response = await axios.put(
        `${BaseURL}/changeStatusUserSide?id=${id}&status=${newStatus}`
      );
      if (
        response.status === 201 &&
        response.data.message === "User details updated successfully"
      ) {
        // Update the state to reflect the new status
        // setUsersArray(usersArray.map((user) =>
        //   user.id === id ? { ...user, status: newStatus } : user
        // ));
        console.log("Status updated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "mobile", headerName: "Mobile", type: "number", width: 160 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
          <Switch
            {...label}
            checked={params.row.status === 1}
            onChange={() => handleStatusChange(params.row.id, params.row.status)}
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
          <button className="btn btn-sm btn-primary" onClick={() => handleEdit(params.row.id)}>Edit</button>
          <button className="btn btn-sm btn-danger ms-2" onClick={() => handleDelete(params.row.id)}>Delete</button>
          <button className="btn btn-sm btn-info ms-2" onClick={()=>handleView(params.row.id)}>View</button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <DataGrid
        rows={usersArray} // Use usersArray directly as rows
        columns={columns}
        pageSize={5}
        pagination
        rowsPerPageOptions={[5, 10]}
      />
    </div>
  );
}

export default Userstable;
